export class TemplateSample {
    public id: number;
    public creationDate: string;
    public lastUpdate: string;

    public yaml: string;
    public code: string;
    public label: string;
    public description: string;
}
