import {Component, OnInit} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {MenuItem, MessageService} from 'primeng/api';
import {BackendConfig} from "src/app/controller/model/backend-config";
import {ProjectConfigService} from "src/app/controller/service/project-config.service";
import {UserConfigService} from "src/app/controller/service/userConfigService";
import {CodeModel} from "@ngstack/code-editor";
import {PojoService} from "../../controller/service/pojo.service";
import {RequestVo} from "../../controller/model/request-vo.model";
import {environment} from "../../../environments/environment";
import {GeneratedProject} from "../../controller/model/generated-project";
import {HttpClient} from "@angular/common/http";
import {NgxSpinnerService} from "ngx-spinner";
import {UserConfig} from "../../controller/model/userConfig";
import {TemplateSample} from "../../controller/model/templateSample";


@Component({
  selector: "app-create-project",
  templateUrl: "./create-project.component.html",
  styleUrls: ["./create-project.component.scss"],
})
export class CreateProjectComponent implements OnInit {
  projectNameDisplay: string = this.projectConfigService.projectName;
  groupIdDisplay: string = this.projectConfigService.groupId;
  domainDisplay: string = this.projectConfigService.domain;
  backendConfig: BackendConfig = this.projectConfigService.backendConfig;

  createProjectForm: UntypedFormGroup = new UntypedFormGroup({
    projectName: new UntypedFormControl("", Validators.required),
    groupId: new UntypedFormControl("", Validators.required),
    domain: new UntypedFormControl("", Validators.required),
    dataSourceUserName: new UntypedFormControl("", Validators.required),
    dataSourcePassword: new UntypedFormControl("", []),
    databaseName: new UntypedFormControl("", Validators.required),
  });

  showBackendTemplates: boolean = false;
  private url = environment.urlBackEnd;
  private _project: GeneratedProject;
  showErrors: boolean;
  showInfos: boolean;

  private _templateSamples: Array<TemplateSample>;
  private _templateSample: TemplateSample;

  constructor(
      private projectConfigService: ProjectConfigService,
      private router: Router,
      private pojoSerice: PojoService,
      private http: HttpClient,
      private userConfigService: UserConfigService,
      private messageService: MessageService,
      private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.createProjectForm.setValue({
      domain: this.domain,
      projectName: this.projectName,
      groupId: this.groupId,
      dataSourceUserName: this.backendConfig.dataSourceUserName,
      dataSourcePassword: this.backendConfig.dataSourcePassword,
      databaseName: this.backendConfig.databaseName,
    });
    this.onChanges();
    this.findAllTemplateSample();
  }

  public findAllTemplateSample(): void {
    this.pojoSerice.findAllTemplateSample().subscribe(data => {
      this.templateSamples = data;
      if (this.templateSamples?.length > 0) {
        this.codeModel = {
          language: "yaml",
          uri: "main.yaml",
          value: this.templateSamples[0].yaml
        };
      }

    });
  }


  onChanges(): void {
    this.createProjectForm.valueChanges.subscribe((val) => {
      const projectName = val.projectName;
      const domain = val.domain;
      const groupId = val.groupId;
      const userName = val.dataSourceUserName;
      const password = val.dataSourcePassword;
      const dataBaseName = val.databaseName;
      this.projectNameDisplay = projectName == "" ? "projectName" : projectName;
      this.domainDisplay = domain == "" ? "com" : domain;
      this.groupIdDisplay = groupId == "" ? "example" : groupId;
      this.backendConfig.databaseName =
          dataBaseName == "" ? "generated" : dataBaseName;
      this.backendConfig.dataSourcePassword = password;
      this.backendConfig.dataSourceUserName =
          userName == "" ? "root" : userName;
    });
  }


  theme = "vs-dark";
  codeModel: CodeModel = {
    language: "yaml",
    uri: "main.yaml",
    value:
        this.requestVo.yamlText === undefined
            ? "#insert your entites"
            : this.requestVo.yamlText,
  };
  options = {
    contextmenu: true,
    minimap: {
      enabled: true,
    },
  };

  items: MenuItem[];


  get requestVo(): RequestVo {
    return this.pojoSerice.requestVo;
  }

  onCodeChanged(value: string) {
    this.requestVo.yamlText = value;
  }

  public generateProject() {
    this.userConfigService.setTechnologiestoGenerate();
    this.userConfigService.userConfig.yamlText = this.requestVo.yamlText;
    this.userConfigService.userConfig.config.projectName = this.projectConfigService.projectName;
    this.userConfigService.userConfig.config.groupId = this.projectConfigService.groupId;
    this.userConfigService.userConfig.config.domain = this.projectConfigService.domain;
    this.userConfigService.userConfig.wantBackend = this.userConfig.wantBackend;
    this.userConfigService.userConfig.wantFrontend = this.userConfig.wantFrontend;

    this.spinner.show();
    this.http
        .post<GeneratedProject>(this.url + "one-step", this.userConfigService.userConfig)
        .subscribe((response) => {
          this.spinner.hide();
          this.project = response;
          if (this.project.errors.length > 0) {
            this.showErrors = true;
            this.showInfos = false;
          } else {
            this.showErrors = false;
            this.showInfos = true;
          }
        }, error => {
          this.spinner.hide();
          this.showErrors = true;
          this.showInfos = false;
          this.project.errors.push("No connection to the server, try later");
        });
  }

  get project(): GeneratedProject {
    if (this._project == null) {
      this._project = new GeneratedProject();
    }
    return this._project;
  }

  set project(value: GeneratedProject) {
    this._project = value;
  }


  get userConfig(): UserConfig {
    return this.userConfigService.userConfig;
  }

  get domain(): string {
    return this.projectConfigService.domain;
  }

  set domain(value: string) {
    this.projectConfigService.domain = value;
  }

  get groupId(): string {
    return this.projectConfigService.groupId;
  }

  set groupId(value: string) {
    this.projectConfigService.groupId = value;
  }

  get projectName(): string {
    return this.projectConfigService.projectName;
  }

  set projectName(value: string) {
    this.projectConfigService.projectName = value;
  }


  get templateSamples(): Array<TemplateSample> {
    if (this._templateSamples == null) {
      this._templateSamples = new Array<TemplateSample>();
    }
    return this._templateSamples;
  }


  get templateSample(): TemplateSample {
    if (this._templateSample == null) {
      this._templateSample = new TemplateSample();
    }
    return this._templateSample;
  }

  set templateSample(value: TemplateSample) {
    this._templateSample = value;
  }

  set templateSamples(value: Array<TemplateSample>) {
    this._templateSamples = value;
  }

  templateSampleChange() {
    this.codeModel = {
      language: "yaml",
      uri: "main.yaml",
      value: this.templateSample.yaml
    };
  }
}
