import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

import { CreateProjectComponent } from "./view/create-project/create-project.component";

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: "",
          component: CreateProjectComponent,
          children: [
            { path: "", component: CreateProjectComponent },
            { path: "create", component: CreateProjectComponent },
            /*  {path: 'view/commande', component: CommandesComponent},
                      {path: 'uikit/formlayout', component: FormLayoutDemoComponent},
                      {path: 'uikit/floatlabel', component: FloatLabelDemoComponent},
                      {path: 'uikit/invalidstate', component: InvalidStateDemoComponent},
                      {path: 'uikit/input', component: InputDemoComponent},
                      {path: 'uikit/button', component: ButtonDemoComponent},
                      {path: 'uikit/table', component: TableDemoComponent},
                      {path: 'uikit/list', component: ListDemoComponent},
                      {path: 'uikit/tree', component: TreeDemoComponent},
                      {path: 'uikit/panel', component: PanelsDemoComponent},
                      {path: 'uikit/overlay', component: OverlaysDemoComponent},
                      {path: 'uikit/media', component: MediaDemoComponent},
                      {path: 'uikit/menu', component: MenusDemoComponent},
                      {path: 'uikit/message', component: MessagesDemoComponent},
                      {path: 'uikit/misc', component: MiscDemoComponent},
                      {path: 'uikit/charts', component: ChartsDemoComponent},
                      {path: 'uikit/file', component: FileDemoComponent},
                      {path: 'utilities/display', component: DisplayComponent},
                      {path: 'utilities/elevation', component: ElevationComponent},
                      {path: 'utilities/flexbox', component: FlexboxComponent},
                      {path: 'utilities/grid', component: GridComponent},
                      {path: 'utilities/icons', component: IconsComponent},
                      {path: 'utilities/widgets', component: WidgetsComponent},
                      {path: 'utilities/spacing', component: SpacingComponent},
                      {path: 'utilities/typography', component: TypographyComponent},
                      {path: 'utilities/text', component: TextComponent},
                      {path: 'pages/crud', component: AppCrudComponent},
                      {path: 'pages/calendar', component: AppCalendarComponent},
                      {path: 'pages/timeline', component: AppTimelineDemoComponent},
                      {path: 'pages/empty', component: EmptyDemoComponent},
                      {path: 'documentation', component: DocumentationComponent} */
          ],
        },
        { path: "**", redirectTo: "/404" },
      ],
      { scrollPositionRestoration: "enabled" }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
