import { Pojo } from "./pojo";
import { UserConfig } from "./userConfig";

export class RequestVo {
  public yamlText: string;
  public pojos: Array<Pojo>;
  public userConfig: UserConfig;
/*
  const formValues = this.createProjectForm.value;
  const projectName = formValues.projectName;
  const domain = formValues.domain;
  const groupId = formValues.groupId;
  const userName = formValues.dataSourceUserName;
  const password = formValues.dataSourcePassword;
  const dataBaseName = formValues.databaseName;
  this.domain = domain;
  this.groupId = groupId;
  this.projectName = projectName;
  this.backendConfig.databaseName = dataBaseName;
  this.backendConfig.dataSourcePassword = password;
  this.backendConfig.dataSourceUserName = userName;
  console.log(this.backendConfig);

 */
}
